<template>
    <div>
        <div class="alert"
             v-if="type == 'success'">
            <v-alert dense
                     dismissible
                     :icon="icon"
                     type="success">
                <span> {{ $t(message) }}</span>
            </v-alert>
        </div>
        <div class="alert"
             v-if="type == 'error'">
            <v-alert dense
                     dismissible
                     :icon="icon"
                     type="error">
                <span> {{ $t(message) }}</span>
            </v-alert>
        </div>
    </div>
</template>
   
<script>

export default {
    props: {
        message: {
            default: 'CORS Error',
            type: String
        },
        type: {
            default: '',
            type: String
        },

    },
    data()
    {
        return {
            icon: 'a'
        }
    },

}
</script>
   
<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.alert {
    width: fit-content;
    position: fixed;
    z-index: 10;
    top: 18%;
    left: 50%;
    transform: translate(-50%, 0);
}

@media screen and (max-width: 500px) {
    .alert {
        min-width: 290px;
    }

}</style>
   