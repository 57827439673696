<template>
    <div>
        <div class="mt-2"
             dir="ltr"
             v-if="selectedLanguage === 'en'">
            <span class="mx-1 referesh-btn">
                <v-btn @click="sendOtpAgain"
                       icon
                       type="button"
                       :disabled="formattedTime > 0"
                       style="cursor: pointer"
                       class="text-blue icon">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </span>
            <span v-if="formattedTime == 0"
                  class="text-blue"
                  style="font-size: 14px; cursor: pointer">
                {{ $t('Send Code again') }}

            </span>
            <span v-if="formattedTime > 0"
                  class="text-blue"
                  style="font-size: 14px; cursor: pointer">
                {{ $t('Retry in') }} {{ formattedTime }} {{ $t('Seconds') }}

            </span>
        </div>

        <div dir="rtl"
             v-if="selectedLanguage === 'ar'"
             class="mt-5">
            <span class="mx-1 referesh-btn">
                <v-btn @click="sendOtpAgain"
                       icon
                       type="button"
                       :disabled="formattedTime > 0"
                       style="cursor: pointer"
                       class="text-blue icon">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </span>
            <span v-if="formattedTime == 0"
                  class="text-blue"
                  style="font-size: 14px; cursor: pointer">
                {{ $t('Send Code again') }}

            </span>
            <span v-if="formattedTime > 0"
                  class="text-blue"
                  style="font-size: 14px; cursor: pointer">
                {{ $t('Retry in') }} {{ formattedTime }} {{ $t('Seconds') }}

            </span>
        </div>
    </div>
</template>
<script>

// import ManageCardActions from './ManageCardActions.vue';

export default {
    components: {

    },
    props: {
        showTimer: {
            default: true,
            type: Boolean
        },
        formattedTime: {
            default: 0,
            type: String
        },
        selectedLanguage: {
            default: 'en',
            type: String
        },
    },

    data: () => ({
        time: 60,

    }),
    mounted()
    {

    },

    methods: {
        sendOtpAgain()
        {

            this.$emit('retry', true);

        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.text-back {
    margin-right: -8px;
}

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}</style>
;